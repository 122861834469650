/**
 * tabs v1.0.1
 */

import Component from 'js/lib/component';

import $ from 'jquery';
import anime from 'animejs';

export default class Tabs extends Component {
  init() {
    this.state = { init: false };
    this.elements({
      tab: '.js-tabs-item',
      label: '.js-tabs-label',
      section: '.js-tabs-section'
    });

    this.$.tab.hide();
    this.$.root.addClass('is-inited');

    this.setActive();
    this.state.init = true;
  }
  events() {
    this.$.label.on('click.tabs', e => {
      e.stopPropagation();
      this.handler(e);
    });

    this.$.label.on('keydown.tabs', e => {
      const key = e.which;
      const direction = {
        37: 'left',
        39: 'right'
      };

      if (direction[key]) {
        const $current = this.$currentLabel;
        const $target = {
          left: $current.prev(),
          right: $current.next()
        };

        if (!$target[direction[key]].length) return;

        this.handler(e, $target[direction[key]]);
      }
    });
  }
  handler(e, target) {
    e.preventDefault();

    const $target = target || $(e.currentTarget);

    this.href = $target.attr('href');
    this.setActive();
  }
  setActive() {
    this.setLabel();
    this.setTab();
  }
  setLabel() {
    this.$.label.removeClass('is-active');
    this.$.label.attr('tabindex', -1);

    this.$selectedLabel.focus();
    this.$selectedLabel.removeAttr('tabindex');
    this.$selectedLabel.addClass('is-active');
  }
  setTab() {
    const $current = this.$currentTab;
    const $next = this.$selectedTab;

    if ($current.length) {
      this.toggleTab($current, $next);
    } else {
      $next.addClass('is-active');
      $next.show();
    }
  }
  toggleTab($current, $next) {
    this.fixHeight();

    anime({
      targets: $current.get(0),
      translateX: 30,
      opacity: 0,
      easing: 'easeInCubic',
      duration: 400,
      complete: () => {
        $current.removeClass('is-active').css({ display: 'none' });
        $next.addClass('is-active').css({
          display: 'block',
          opacity: 0,
          transform: 'translateX(-30px)'
        });

        this.updateHeight();

        anime({
          targets: $next.get(0),
          translateX: 0,
          opacity: 1,
          easing: 'easeOutCubic',
          duration: 400
        });
      }
    });
  }
  set href(href) {
    location.hash = href || '';
  }
  get href() {
    return location.hash || '';
  }
  get $currentTab() {
    return this.$.tab.filter('.is-active');
  }
  get $currentLabel() {
    return this.$.label.filter('.is-active');
  }
  get $selectedLabel() {
    const $filtered = this.$.label.filter(`[href="${this.href}"]`);
    return $filtered.length ? $filtered : this.$.label.first();
  }
  get $selectedTab() {
    const $filtered = this.$.tab.filter(this.href);
    return $filtered.length ? $filtered : this.$.tab.first();
  }
  fixHeight() {
    const height = this.$.section.outerHeight();
    this.$.section.height(height);
  }
  updateHeight() {
    const height = this.$currentTab.outerHeight();

    anime({
      targets: this.$.section.get(0),
      duration: 400,
      easing: 'easeOutCubic',
      height,
      complete: () => {
        this.$.section.removeAttr('style');
      }
    });
  }
  destroy() {
    if (!this.state.init) return;

    this.$.label.off('click.tabs');
    this.$.label.off('keydown.tabs');

    delete this.state;

    return this;
  }
}

Component.mount(Tabs, '.js-tabs');
