/* global $ */

import Component from 'js/lib/component';
import Article from '~/article/article';

const classList = {
  root: '.js-post',
  link: '.js-post-link'
};

export default class Post extends Component {
  init() {
    this.state = { init: false };
    this.elements(classList);
  }
  events() {
    this.$.link.on('click', e => {
      e.preventDefault();

      const href = this.$.link.attr('href');

      this.handler(href);
    });

    this.state.init = true;
  }
  handler(href) {
    const { id } = this.$.root.data();
    const timeout = setTimeout(() => {
      this.$.root.addClass('is-loading');
    }, 600);

    Article.open(id)
      .then(() => {
        clearTimeout(timeout);
        this.$.root.removeClass('is-loading');
      })
      .catch(() => {
        window.location = href;
      });
  }
  destroy() {
    if (!this.state.init) return;

    this.$.link.off('click');

    delete this.state;

    return this;
  }
  static get selector() {
    return classList.root;
  }
}

Component.mount(Post, classList.root);
