/**
 * nav
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import 'jquery.pubsub';

const classList = {
  trigger: '.js-nav-tab-toggle',
  triggerMobile: '.js-nav-mobile-toggle',
  dropmenu: '.js-nav-mobile-drop',
  container: '.js-nav-mobile'
};

class Navigation extends Component {
  init() {
    this.state = {
      focus: false,
      init: false
    };

    this.elements(classList);
    this.state.init = true;
  }
  events() {
    this.$.trigger.on('click', e => {
      e.preventDefault();
      this.click(e);
    });

    this.$.triggerMobile.on('click', e => {
      e.preventDefault();
      this.clickMobile(e);
    });

    this.$.container.on('click', '.js-nav-mobile-submenu', e => {
      e.preventDefault();
      this.clickSubmenu(e);
    });

    $.on('nav-mobile.close', () => {
      this.closeOpened();
    });

  }
  click(e) {
    const $target = $(e.target);

    if ($target.is(this.$.trigger) || $.contains(this.$.trigger.get(0), e.target)) {
      e.preventDefault();
      const $drop = this.$.container;

      if (this.$.trigger.is('.is-open')) {
        this.close($target, $drop);
      } else {
        // this.closeOpened(true);
        this.open($target, $drop);
      }

    }
  }
  clickMobile(e) {
    const $target = $(e.target);

    if ($target.is(this.$.triggerMobile.get(0)) || $.contains(this.$.triggerMobile.get(0), e.target)) {
      const $drop = this.$.container;
    
      if (this.$.triggerMobile.is('.is-open')) {
        this.close($target, $drop);

        return false;
      }

      // this.closeOpened(true);
      this.open($target, $drop);
    }
  }
  clickSubmenu(e) {
    const $target = $(e.target);

    //if ($target.is(this.$.trigger) || jQuery.contains(this.$.trigger.get(0), e.target)) {
    //}
    const $drop = $target.closest('.has-children').find('.js-nav-mobile-drop');

    let btn = $target;
    if ($target.hasClass("nav-mobile__submenu-btn-icon")) {
      btn = $target.parent();
    }

    $(".js-nav-mobile-submenu.is-open").not(btn).each(function(idx, elem){
      $(elem).removeClass('is-open');
      $(elem).parent().parent().find('.js-nav-mobile-drop').stop(true).removeClass('is-open').slideUp();
    });
    
    if (btn.hasClass('is-open')) {
      this.closeSubmenu($target, $drop, btn);

      return false;
    }

    // this.closeOpened(true);
    this.openSubmenu($target, $drop, btn);
  }
  isOpen($target) {
    return $target.is('.is-open');
  }
  open($target, $drop) {
    this.$.trigger.addClass('is-open');
    this.$.triggerMobile.addClass('is-open');
    $drop.stop(true).addClass('is-open').slideDown();
    this.$.trigger.find(".menu-btn__text").html('Закрыть');
    this.$.body.css({
      "overflow":"hidden"
    });
  }
  close($target, $drop) {
    this.$.trigger.removeClass('is-open');
    this.$.triggerMobile.removeClass('is-open');
    $drop.stop(true).removeClass('is-open').slideUp();
    this.$.trigger.find(".menu-btn__text").html('Меню');
    this.$.body.css({
      "overflow":"unset"
    });
  }
  closeOpened(focused = false) {
    this.$.body.css({
      "overflow":"unset"
    });
    this.$.triggerMobile.removeClass('is-open');
    this.$.container
      .filter('.is-open')
      .hide()
      .removeClass('is-open');    
  }
  openSubmenu($target, $drop, btn) {
    //this.$.trigger.addClass('is-open');
    $drop.stop(true).addClass('is-open').slideDown();
    btn.addClass('is-open');
  }
  closeSubmenu($target, $drop, btn) {
    //this.$.trigger.addClass('is-open');
    $drop.stop(true).removeClass('is-open').slideUp();
    btn.removeClass('is-open');
  }
  destroy() {
    if (!this.state.init) return;

    this.$.container.off('click');
    this.$.trigger.off('click');
    this.$.triggerMobile.off('click');

    $.off('nav-mobile.close');

    delete this.state;
  }
}

export default Component.mount(Navigation, '.header', true);
