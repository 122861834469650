import $ from 'jquery';
import Component from 'js/lib/component';

const classList = {
  VK: '.js-share-vk',
  FACEBOOK: '.js-share-fb',
  OK: '.js-share-ok'
};

class Share extends Component {
  init() {
    this.state = { init: false };
    this.events();

    return this;
  }
  events() {
    this.$.document
      // VK
      .on('click.share', classList.VK, () => {
        const { title, href } = this.getParams();
        const winTop = (window.screen.height - 620) / 2;
        const winLeft = (window.screen.width - 660) / 2;
        const url = `//vk.com/share.php?url=${href}&tltl=${title}&cmg=1`;

        window.open(
          url,
          'shareSite',
          `width=550px,height=268px,resizable=yes,scrollbars=yes,left=${winLeft},top=${winTop}`
        );
      })
      // Facebook
      .on('click.share', classList.FACEBOOK, () => {
        const { title, href } = this.getParams();
        const url = `//www.facebook.com/sharer.php?u=${href}&title=${title}`;
        window.open(url);
      })
      // Odnoklasniki
      .on('click.share', classList.OK, () => {
        const { title, href } = this.getParams();
        const url = `//connect.ok.ru/offer?url=${href}&title=${title}`;
        window.open(url);
      });

    this.state.init = true;
  }
  getParams() {
    return {
      title: document.title,
      href: document.location.href
    };
  }
  destroy() {
    if (!this.state.init) return;

    this.$.document.off('click.share');

    delete this.state;

    return this;
  }
}

export default new Share().init();
