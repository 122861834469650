/**
 * modal
 */

import Component from 'js/lib/component';
import Page from '~/page/page';

import $ from 'jquery';
import VanillaModal from 'vanilla-modal';

const focusableList = 'a, button, input, textarea, select';

class Modal extends Component {
  init() {
    this.state = { init: false };

    this.modal = new VanillaModal({
      modal: '.modal',
      modalInner: '.modal__inner',
      modalContent: '.modal__content',
      loadClass: 'modal-init',
      onBeforeOpen: () => {
        Page.bodyOverflowEnable();
        this.onTransition().then(() => {
          this.setFocus();
        });
      },
      onBeforeClose: () => {
        this.onTransition().then(() => {
          Page.bodyOverflowDisable();
          this.unsetFocus();
        });
      }
    });

    this.elements({
      title: '.js-modal-title',
      header: '.js-modal-header',
      content: '.js-modal-content'
    });

    this.$.focusable = $(focusableList);
    this.$.modal = $('.js-modal-default');
    this.state.init = true;
  }
  events() {
    this.$.document.on('click.modal', '[data-modal-close]', () => this.close());
    this.$.document.on('click.modal', '[data-modal]', e => this.handler(e));
  }
  handler(e) {
    e.preventDefault();

    const $target = $(e.currentTarget);
    const data = $target.data('modal');

    if (name.indexOf('/') === -1) {
      const modalId = `#modal-${data}`;
      const title = $(modalId).data('title') || null;
      this.setTitle(title);
      this.open(modalId);
    } else {
      const timeout = setTimeout(() => {
        $target.addClass('is-loading');
      }, 800);
      this.getContent(data)
        .then(() => {
          this.open('#modal');
          this.onTransition().then(() => {
            clearTimeout(timeout);
            $target.removeClass('is-loading');
          });
        })
        .fail(err => {
          clearTimeout(timeout);
          $target.removeClass('is-loading');
          throw new Error(err);
        });
    }
  }
  getContent(url) {
    return $.ajax(url).then(({ title, content }) => {
      this.setContent(content);
      this.setTitle(title);
    });
  }
  setContent(content) {
    if (this.$.modal.length) {
      this.$.modal.append(content);
    }
  }
  setTitle(title) {
    if (title) {
      this.$.header.show();
      this.$.title.text(title);
    } else {
      this.$.header.hide();
    }
  }
  open(id) {
    if (this.isOpen()) {
      this.close().then(() => {
        this.modal.open(id);
      });
    } else {
      this.modal.open(id);
    }
  }
  close() {
    if (!this.isOpen()) return Promise.resolve();

    return this.nextTick().then(() => {
      this.modal.close();
      return this.onTransition();
    });
  }
  onTransition() {
    return new Promise(resolve => {
      this.$.root.one('transitionend', () => {
        resolve();
      });
    });
  }
  isOpen() {
    return this.modal.isOpen;
  }
  setFocus() {
    const $focusableInModal = this.$.root.find(focusableList);

    this.$.focusable.attr('tabindex', -1);
    $focusableInModal.removeAttr('tabindex');
  }
  unsetFocus() {
    this.$.focusable.removeAttr('tabindex');
  }
  destroy() {
    if (!this.state.init) return;

    this.modal.destroy();

    this.$.document.off('click.modal');
    this.$.document.off('click.modal');

    delete this.modal;
    delete this.state;
  }
}

export default Component.mount(Modal, '.js-modal', true);
