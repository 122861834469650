import $ from 'jquery';
import _filter from 'lodash/_baseFilter.js';

export const allComponents = {};
export const components = {};

export default class Component {
  constructor(element, selector = '') {
    this.selector = selector;
    this.name = this.constructor.name;

    this.methods = {};

    this.$ = {
      window: $(window),
      document: $(document),
      body: $('.js-body'),
      page: $('.js-page'),
      root: $(element)
    };

    if (this.$.root.length) {
      this.init();
      this.events();
      this.inited();
    }
  }
  init() {
    console.warn(`Need create init method for "${this.selector}"`);
  }
  elements(elements) {
    for (let element in elements) {
      if (element !== 'root') {
        this.$[element] = this.$.root.find(elements[element]);
      }
    }
  }
  events() {
    // no events
  }
  handler(e) {
    const type = e.data.type;
    const method = this.methods[type];

    if (method) {
      this.methods[type].call(this, e);
    } else {
      console.warn('Method undefined');
    }
  }
  inited() {}
  removeRoot() {
    this.$.root.html('').remove();
    delete this.$.root;
  }
  nextTick() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }
  getState() {
    return this.state || {};
  }
  static mount(Constructor, selector, isSingleton = false) {
    const $elements = $(selector);

    if (!allComponents.hasOwnProperty(selector)) {
      allComponents[selector] = { Constructor, selector, isSingleton };
    }

    if (!Boolean($elements.length)) return {};

    const init = element => {
      const id = Math.random()
        .toString(16)
        .slice(2);

      element.selector = selector;
      element.componentId = id;

      try {
        return {
          id,
          component: new Constructor(element, selector)
        };
      } catch (error) {
        console.error(error);
        return {};
      }
    };

    if (isSingleton) {
      const element = $elements.get(0);
      const { id, component } = init(element);

      components[id] = component;

      return component;
    } else {
      $elements.map((i, element) => {
        const { id, component } = init(element);
        components[id] = component;
      });
    }
  }
  static initComponent(Constructor, $node) {
    if (
      Constructor.in($node, Constructor.selector) ||
      Constructor.is($node, Constructor.selector)
    ) {
      const $element = Constructor.is($node, Constructor.selector)
        ? $node
        : Constructor.find($node, Constructor.selector);
      const ids = [];

      $element.each((i, element) => {
        const id = Math.random()
          .toString(16)
          .slice(2);

        element.selector = Constructor.selector;
        element.componentId = id;

        try {
          components[id] = new Constructor(element, Constructor.selector);
          ids.push(id);
        } catch (error) {
          console.error(error);
        }
      });

      return ids;
    }

    return null;
  }
  static getAllComponentInstance(name) {
    return _filter(
      components,
      component => component.name.toLowerCase() === name.toLowerCase()
    );
  }
  static getInstances($element, selector) {
    return $element
      .find(selector)
      .map((i, component) => components[component.componentId]);
  }
  static getInstance($element) {
    return components[$element.get(0).componentId] || null;
  }
  static isFunction(fn) {
    return fn && typeof fn === 'function';
  }
  static in($node, selector) {
    return Boolean($node.find(selector).length);
  }
  static is($node, selector) {
    return $node.is(selector);
  }
  static find($node, selector) {
    return $node.find(selector);
  }
  static reinit() {
    for (let key in components) {
      const component = components[key];

      if (component.destroy) {
        component.destroy();
      }

      delete components[key];
    }

    for (let key in allComponents) {
      const component = allComponents[key];
      const { Constructor, selector, isSingleton } = component;

      Component.mount(Constructor, selector, isSingleton);
    }
  }
}
