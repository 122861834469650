/**
 * accordion-item
 */

/* global $ */

import Component from 'js/lib/component';
import 'jquery.easing';

const classList = {
  root: '.js-accordion-item',
  toggle: '.js-accordion-item-toggle',
  inner: '.js-accordion-item-inner'
};

export default class AccordionItem extends Component {
  init() {
    this.state = {
      open: false,
      init: false
    };

    this.elements(classList);
  }
  events() {
    this.$.document.on('click.accordion', classList.toggle, e => {
      const $target = $(e.currentTarget);

      if ($target.is(this.$.toggle)) {
        this.state.open ? this.close() : this.open();
      }
    });

    this.$.window.on('keydown.accordion', e => {
      if (e.keyCode === 27) {
        this.close();
      }
    });

    this.state.init = true;
  }
  open() {
    this.$.root.addClass('is-open');
    this.$.inner.slideDown({ speed: 300, easing: 'easeOutCubic' });
    this.$.toggle.attr('aria-expanded', true);

    this.state.open = true;
  }
  close() {
    this.$.root.removeClass('is-open');
    this.$.inner.slideUp({ speed: 300, easing: 'easeOutCubic' });
    this.$.toggle.attr('aria-expanded', false);

    this.state.open = false;
  }
  destroy() {
    if (!this.state.init) return;

    this.$.document.off('click.accordion');
    this.$.window.off('keydown.accordion');

    delete this.state;

    return this;
  }
}

Component.mount(AccordionItem, classList.root);
