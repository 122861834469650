/* global $ */

import Component from 'js/lib/component';
import anime from 'animejs';

const classList = {
  root: '.js-search-form',
  box: '.js-search-form-box',
  field: '.js-search-form-field',
  open: '.js-search-form-open',
  close: '.js-search-form-close'
};

export default class SearchForm extends Component {
  init() {
    this.state = {
      init: false,
      animating: false
    };
    this.elements(classList);
  }
  events() {
    this.$.open.on('click.search', this.open.bind(this));
    this.$.close.on('click.search', this.close.bind(this));

    this.$.document.on('click.search', e => {
      const $target = $(e.target);
      const { root } = classList;

      if (!($target.is(root) || $target.closest(root).length)) {
        this.close();
      }
    });

    this.$.window.on('keydown.search', e => {
      const key = e.which;
      const map = {
        27: 'close'
      };

      if (map[key]) {
        this[map[key]].call(this);
      }
    });

    this.state.init = true;
  }
  open() {
    if (this.state.animating) return;

    this.state.animating = true;

    this.$.body.addClass('search-active');
    this.$.box.css({
      opacity: 0,
      display: 'block',
      transform: 'perspective(1400px) rotateX(-30deg)'
    });

    setTimeout(() => {
      anime({
        targets: this.$.box.get(0),
        opacity: 1,
        perspective: 1400,
        rotateX: 0,
        easing: 'easeOutCubic',
        duration: 300,
        complete: () => {
          this.state.animating = false;
          this.$.field.focus();
        }
      });
    }, 100);
  }
  close() {
    if (this.state.animating) return;

    this.state.animating = true;

    anime({
      targets: this.$.box.get(0),
      opacity: 0,
      perspective: 1400,
      rotateX: -30,
      easing: 'easeOutCubic',
      duration: 300,
      complete: () => {
        this.$.box.css('display', 'none');
        this.$.body.removeClass('search-active');
        this.state.animating = false;
        this.$.field.val('');
      }
    });
  }
  destroy() {
    if (!this.state.init) return;

    this.$.open.off('click.search');
    this.$.close.off('click.search');
    this.$.document.off('click.search');
    this.$.window.off('keydown.search');

    delete this.state;
  }
  static get selector() {
    return classList.root;
  }
}

Component.mount(SearchForm, classList.root);
