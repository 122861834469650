/**
 * specialists
 */

/* global $ */

import Component from 'js/lib/component';
import anime from 'animejs';

const classList = {
  root: '.js-specialists',
  filter: '.js-specialists-filter',
  amount: '.js-specialists-amount',
  content: '.js-specialists-content',
  item: '.js-specialists-item'
};

const cache = {};

export default class Specialists extends Component {
  init() {
    this.state = { init: false };
    this.elements(classList);
  }
  events() {
    this.$.filter.on('change.specialists', e => {
      e.stopPropagation();

      const $target = $(e.target);
      const id = Number($target.val());

      this.handler(id);
    });

    this.state.init = true;
  }
  handler(id) {
    if (cache[id]) {
      this.toggle(cache[id]);
    } else {
      const timeout = setTimeout(() => {
        this.$.root.addClass('is-loading');
      }, 600);

      this.request(id)
        .then(({ content, amount }) => {
          cache[id] = { content, amount };
          this.toggle({ content, amount });
        })
        .always(() => {
          clearTimeout(timeout);
          this.$.root.removeClass('is-loading');
        });
    }
  }
  request(id) {
    return $.post('/employees/filter/' + id)
      .then(({ content, amount }) => {
        return { content, amount };
      })
      .fail(() => {
        console.error('Ошибка при загрузке данных');
      });
  }
  toggle({ content, amount }) {
    this.hide().then(() => {
      const $content = $(content);
      const $items = $content.find(classList.item);

      if ($items.length > 4) {
        $items.css('opacity', 0);
      }

      this.set({ $content, amount });
      this.show($items);
    });
  }
  hide() {
    return Promise.all([
      anime({
        targets: this.$.content.get(0),
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic'
      }).finished,
      anime({
        targets: this.$.amount.parent().get(0),
        opacity: 0,
        duration: 300,
        easing: 'easeOutCubic'
      }).finished
    ]);
  }
  show($items) {
    anime({
      targets: this.$.amount.parent().get(0),
      opacity: 1,
      duration: 500,
      easing: 'easeOutCubic'
    });

    anime({
      targets: this.$.content.get(0),
      opacity: 1,
      duration: 500,
      easing: 'easeOutCubic'
    });

    if ($items.length > 4) {
      anime({
        targets: $items.get(),
        opacity: 1,
        duration: 500,
        delay: (el, i) => {
          return i * 40;
        },
        easing: 'easeOutCubic'
      });
    }
  }
  set({ $content, amount }) {
    this.$.amount.html(amount);
    this.$.content.html($content);
  }
  destroy() {
    if (!this.state.init) return;

    this.$.filter.off('change.specialists');

    delete this.state;

    return this;
  }
  static get selector() {
    return classList.root;
  }
}

Component.mount(Specialists, classList.root);
