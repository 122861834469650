import Barba from "barba.js";
import $ from "jquery";
import "jquery.pubsub";
import anime from "animejs";
import component from "js/lib/component";

Barba.Pjax.Dom.containerClass = "js-page-content";
Barba.Pjax.Dom.wrapperId = "page";
Barba.Pjax.ignoreClassLink = "js-no-pjax";

function setActiveLinks() {
  const href = window.location.pathname.replace("/", "");
  const $links = $("a");

  $links.removeClass("is-active");
  $links.each((i, link) => {
    const $link = $(link);

    if ($link.attr("href") === href) {
      $link.addClass("is-active");

      const $parent = $link.closest(".js-nav-drop").siblings(".js-nav-link");

      if ($parent.length) {
        $parent.addClass("is-active");
      }
    }
  });
}

const FadeTransition = Barba.BaseTransition.extend({
  start() {
    Promise.all([this.newContainerLoading, this.fadeOut()]).then(
      this.fadeIn.bind(this)
    );
  },

  fadeOut() {
    return $(this.oldContainer)
      .animate({ opacity: 0 })
      .promise();
  },

  fadeIn() {
    $(this.oldContainer).hide();
    $(this.newContainer).css({
      visibility: "visible",
      transform: "translateY(20px)",
      opacity: 0
    });

    anime({
      targets: this.newContainer,
      opacity: 1,
      translateY: 0,
      duration: 500,
      easing: "easeOutCubic",
      complete: () => {
        this.done();
      }
    });
  }
});

Barba.Dispatcher.on("linkClicked", () => {
  $.trigger("nav.close");
  $.trigger("nav-mobile.close");
});

Barba.Dispatcher.on("newPageReady", () => {
  setActiveLinks();
  $(window).scrollTop(0);
});

Barba.Dispatcher.on("transitionCompleted", () => {
  component.reinit();
});

Barba.Pjax.getTransition = () => FadeTransition;
Barba.Pjax.start();
