/**
 * gallery
 */

import Component from 'js/lib/component';
import Page from '~/page/page';
import 'lightgallery';

export default class Gallery extends Component {
  init() {
    this.state = { init: false };
    this.gallery();
  }
  gallery() {
    const _options = {
      selector: '.js-gallery-image',
      download: false,
      counter: false,
      startClass: 'lg-fade',
      getCaptionFromTitleOrAlt: false,
      hideBarsDelay: 1000000000
    };

    this.$.root.lightGallery(_options);
    this.state.init = true;
  }
  events() {
    this.$.root.on('onAfterOpen.lg', () => {
      this.$.body.addClass('gallery-visible');
      Page.bodyOverflowEnable();
    });

    this.$.root.on('onBeforeClose.lg', () => {
      this.$.body.removeClass('gallery-visible');
    });

    this.$.root.on('onCloseAfter.lg', () => {
      Page.bodyOverflowDisable();
    });
  }
  destroy() {
    if (!this.state.init) return;

    this.$.root.data('lightGallery').destroy(true);

    this.$.root.off('onAfterOpen.lg');
    this.$.root.off('onBeforeClose.lg');
    this.$.root.off('onCloseAfter.lg');

    delete this.state;

    return this;
  }
}

Component.mount(Gallery, '.js-gallery');
