import Component from 'js/lib/component';

class Page extends Component {
  init() {
    this.state = {
      locked: false,
      animating: false
    };

    this.elements();

    this.breakpoint = {
      lg: 1599,
      xl: 1439,
      l: 1179,
      md: 959,
      m: 767,
      s: 559,
      xs: 320
    };

    this.mq = {
      desktop: matchMedia('(min-width: 1365px)'),
      tablet: matchMedia('(max-width: 1365px)'),
      mobile: matchMedia('(max-width: 560px)')
    };
  }
  events() {
    this.$.window.on('keydown', e => {
      if (this.state.locked) return;

      if (e.keyCode === 27) {
      }
    });
  }
  lockUI() {
    if (this.state.locked) return;

    this.$.body.addClass('is-locked');
    this.state.locked = true;
  }
  unlockUI() {
    if (!this.state.locked) return;

    this.$.body.removeClass('is-locked');
    this.state.locked = false;
  }
  bodyOverflowEnable() {
    this.$.body.css({ paddingRight: this.getScrollbarWidth() });
    this.$.body.addClass('is-overflow');
  }
  bodyOverflowDisable() {
    this.$.body.removeAttr('style');
    this.$.body.removeClass('is-overflow');
  }
  getScrollbarWidth() {
    return window.innerWidth - this.$.document.width();
  }
}

export default Component.mount(Page, '.js-page', true);
