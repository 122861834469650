import Component from 'js/lib/component';
import Swiper from 'swiper';

export default class SpecialistsRoll extends Component {
  init() {
    this.state = { init: true };
    this.elements({
      arrows: '.js-specialists-roll-control',
      cards: '.js-specialists-roll-card'
    });

    this.swiper();

    if (this.$.cards.length > 4) {
      this.showControls();
    }
  }
  showControls() {
    this.$.arrows.addClass('is-visible');
  }
  swiper() {
    this.swiper = new Swiper('.js-specialists-roll-slider', {
      slidesPerView: 4,
      slidesPerGroup: 4,
      spaceBetween: 30,
      speed: 800,
      wrapperClass: 'js-specialists-roll-cards',
      slideClass: 'js-specialists-roll-card',
      navigation: {
        prevEl: '.js-specialists-roll-prev',
        nextEl: '.js-specialists-roll-next'
      },
      on: {
        init: () => {
          this.$.root.addClass('is-inited');
          this.state.init = true;
        }
      }
    });
  }
  destroy() {
    if (!this.state.init) return;

    this.swiper.destroy();

    delete this.swiper;
    delete this.state;

    return this;
  }
}

Component.mount(SpecialistsRoll, '.js-specialists-roll');
