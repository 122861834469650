import $ from "jquery";

import "js/lib/svg";
import "js/lib/objectFit";
import "js/lib/hello";
import "js/lib/focus";
// import 'js/lib/quicklink';
import "js/lib/pageTransition";

import "~/nav/nav";
import "~/nav-mobile/nav-mobile";
import "~/specialists/specialists";
import "~/specialists-roll/specialists-roll";
import "~/select/select";
import "~/tabs/tabs";
import "~/gallery/gallery";
import "~/accordion-item/accordion-item";
import "~/map/map";
import "~/post/post";
import "~/share/share";
import "~/search-form/search-form";
import "~/modal/modal";
import "~/hero/hero";
import "~/phones/phones";
import "~/pricelist/pricelist";

/* Add jQuery to global scope */
window.jQuery = window.$ = $;
