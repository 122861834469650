/* global $ */

import Component from 'js/lib/component';

const classList = {
  root: '.js-phones-dropdown',
  list: '.phones__list',
  link: '.js-phones-dropdown-link'
};

export default class Phones extends Component {
  init() {
    this.state = { init: false };
    this.elements(classList);
  }
  events() {
    this.$.link.on('click', e => {
      e.preventDefault();

      this.handler();
    });

    this.state.init = true;
  }
  handler() {
    //const { id } = this.$.root.data();
    //console.log(this.$.link);

    if (this.$.link.hasClass("active")) {
      this.$.list.css({
        "overflow":"hidden",
        "height":"53px"
      });
  
      this.$.list.find(".phones__item").each(function(idx, item){
        if (idx == 0) {
          return true;
        }

        $(item).css({
          "display":"none"
        });
      });
  
      this.$.list.css({
        "height":"auto"
      });

      this.$.link.removeClass("active");

      this.$.root.removeClass("active");
    } else {
      this.$.list.css({
        "overflow":"hidden",
        "height":"53px"
      });
  
      this.$.list.find(".phones__item").css({
        "display":"block"
      });
  
      this.$.list.css({
        "height":"auto"
      });

      this.$.link.addClass("active");
      this.$.root.addClass("active");
    }
    
  }
  destroy() {
    if (!this.state.init) return;

    this.$.link.off('click');

    delete this.state;

    return this;
  }
  static get selector() {
    return classList.root;
  }
}

Component.mount(Phones, classList.root);
