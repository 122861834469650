/**
 * Select
 */

import SlimSelect from 'slim-select';
import Component from 'js/lib/component';

export default class Select extends Component {
  init() {
    this.state = { init: false };
    this.select = new SlimSelect({
      select: this.$.root.get(0),
      placeholder: this.$.root.data('placeholder'),
      showSearch: false
    });

    this.$.root.addClass('is-inited');
    this.state.init = true;
  }
  destroy() {
    if (!this.state.init) return;

    this.select.destroy();

    delete this.select;
    delete this.state;

    return this;
  }
}

Component.mount(Select, '.js-select');
