/**
 * nav
 */

import $ from 'jquery';
import Component from 'js/lib/component';
import 'jquery.pubsub';

const classList = {
  trigger: '.js-nav-toggle',
  dropmenu: '.js-nav-drop',
  close: '.js-nav-close'
};

class Navigation extends Component {
  init() {
    this.state = {
      focus: false,
      init: false
    };

    this.elements(classList);
    this.state.init = true;
  }
  events() {
    this.$.close.on('click.nav', e => this.click(e));
    this.$.document.on('click.nav', e => this.handler(e));
    this.$.window.on('keydown.nav', e => this.handler(e));
    this.$.trigger.on('mouseenter.nav', e => this.handler(e));
    $.on('nav.close', () => {
      this.closeOpened();
    });
  }
  handler(e) {
    const methods = {
      click: this.click,
      mouseenter: this.mouseenter,
      keydown: this.keydown
    };

    const type = e.type;
    const method = methods[type];

    method.call(this, e);
  }
  click(e) {
    const $target = $(e.target);

    if ($target.is(this.$.close)) {
      this.closeOpened();
      return;
    }

    if ($target.is(this.$.trigger)) {
      e.preventDefault();

      const $drop = $target.siblings().filter(this.$.dropmenu);
      this.state.focus = true;

      if ($target.is('.is-open')) {
        this.state.focus = false;
        this.close($target, $drop);

        return;
      }

      this.closeOpened(true);
      this.open($target, $drop);
    } else {
      if (!$target.closest(this.$.dropmenu).length) {
        this.closeOpened();
      }
    }
  }
  mouseenter(e) {
    if (!this.state.focus) return;

    const $target = $(e.target);
    const $drop = $target.siblings().filter(this.$.dropmenu);

    if (!this.isOpen($target)) {
      this.closeOpened(true);
      this.open($target, $drop);
    }
  }
  keydown(e) {
    if (e.keyCode === 27) {
      this.closeOpened();
    }
  }
  isOpen($target) {
    return $target.is('.is-open');
  }
  open($target, $drop) {
    $target.addClass('is-open');
    $drop.show().addClass('is-open');
  }
  close($target, $drop) {
    $target.removeClass('is-open');
    $drop.hide().removeClass('is-open');
  }
  closeOpened(focused = false) {
    this.state.focus = focused;
    this.$.trigger.removeClass('is-open');
    this.$.dropmenu
      .filter('.is-open')
      .hide()
      .removeClass('is-open');
  }
  destroy() {
    if (!this.state.init) return;

    this.$.close.off('click.nav');
    this.$.document.off('click.nav');
    this.$.window.off('keydown.nav');
    this.$.trigger.off('mouseenter.nav');

    $.off('nav.close');

    delete this.state;
  }
}

export default Component.mount(Navigation, '.js-nav', true);
