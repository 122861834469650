/**
 * modal
 */

import Component from "js/lib/component";

import $ from "jquery";

class Pricelist extends Component {
  init() {
    this.state = { init: true };
    this.elements({
      toggle: ".js-pricelist-toggle",
      body: ".js-pricelist-body"
    });
    this.toggle();
  }
  toggle() {
    this.$.toggle.on("click", e => {
      let target = e.target;
      this.$.root.toggleClass("is-active");
    });
  }
}

export default Component.mount(Pricelist, ".js-pricelist");
