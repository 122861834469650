import Component from "js/lib/component";
import Swiper from "swiper";

export default class Hero extends Component {
  init() {
    this.state = { init: true };
    this.elements({
      next: ".js-hero-next",
      prev: ".js-hero-prev"
    });

    this.swiper();
  }

  swiper() {
    let hero = new Swiper(".js-hero-container", {
      wrapperClass: "js-hero-wrapper",
      slideClass: "js-hero-slide",
      slidesPerView: 1,
      spaceBetween: 1000,
      effect: "fade",
      speed: 800,
      parallax: true,
      navigation: {
        nextEl: ".js-hero-next",
        prevEl: ".js-hero-prev"
      }
    });
  }
}

Component.mount(Hero, ".js-hero");
