/**
 * article
 */

/* global $ */

import Component from 'js/lib/component';
import Page from '~/page/page';
import anime from 'animejs';

const classList = {
  root: '.js-article',
  close: '.js-article-close',
  prev: '.js-article-prev',
  next: '.js-article-next',
  overlay: '.js-article-overlay',
  frame: '.js-article-frame'
};

const cache = {};

class Article extends Component {
  init() {
    this.state = {
      $article: null,
      animating: false,
      open: false,
      prevId: null,
      nextId: null,
      location: {
        current: null,
        default: null
      },
      title: {
        current: null,
        default: null
      }
    };

    return this;
  }
  addEvents() {
    this.$.document.on('click.article', classList.close, this.hide.bind(this));
    this.$.document.on('click.article', classList.prev, this.prev.bind(this));
    this.$.document.on('click.article', classList.next, this.next.bind(this));
    this.$.window.on('keydown.article', e => {
      const key = e.which;
      const map = {
        27: 'hide',
        37: 'prev',
        39: 'next'
      };

      if (map[key]) {
        this[map[key]].call(this);
      }
    });
  }
  removeEvents() {
    this.$.document.off('click.article');
    this.$.window.off('keydown.article');
  }
  open(id) {
    if (!id || this.state.animating) return;

    return new Promise((resolve, reject) => {
      if (cache[id]) {
        resolve(cache[id]);
      } else {
        this.request(id)
          .then(({ prevId, nextId, url, title, content }) => {
            cache[id] = { prevId, nextId, url, title, content };
            resolve(cache[id]);
          })
          .catch(error => {
            reject(error);
          });
      }
    })
      .then(({ prevId, nextId, url, title, content }) => {
        this.state.prevId = Number(prevId) || null;
        this.state.nextId = Number(nextId) || null;
        this.state.location.current = url;
        this.state.title.current = title;
        this.state.animating = true;

        return this.show(content).then(() => {
          this.setUrl(this.state.location.current);
          this.setTitle(this.state.title.current);
          this.state.open = true;
          this.state.animating = false;
        });
      })
      .catch(() => {
        throw new Error();
      });
  }
  show(content) {
    const $content = $(content);

    if (this.state.$article !== null) {
      const $frame = $content.find(classList.frame);

      return new Promise(resolve => {
        anime({
          targets: this.state.$frame.get(0),
          duration: 450,
          opacity: 0,
          translateX: 20,
          easing: 'easeOutCubic',
          complete: () => {
            this.state.$frame.css('transform', 'translateX(-20px)');
            this.state.$frame.html($frame.html());
            this.setControl();

            anime({
              targets: this.state.$frame.get(0),
              duration: 450,
              opacity: 1,
              translateX: 0,
              easing: 'easeOutCubic',
              complete: () => {
                resolve();
              }
            });
          }
        });
      });
    } else {
      this.addEvents();
      this.setElements($content);
      this.setControl();

      this.state.location.default = location.href;
      this.state.title.default = document.title;

      this.state.$article.css('display', 'block');
      this.state.$overlay.css('opacity', 0);
      this.state.$frame.css('opacity', 0);
      this.state.$frame.css('transform', 'translateY(20px)');

      Page.bodyOverflowEnable();

      this.$.body.append(this.state.$article);

      const $targets = this.state.$prev
        .add(this.state.$next)
        .add(this.state.$close)
        .add(this.state.$overlay);

      return Promise.all([
        anime({
          targets: $targets.get(0),
          duration: 550,
          opacity: 1,
          easing: 'easeOutCubic'
        }).finished,
        anime({
          targets: this.state.$frame.get(0),
          duration: 500,
          delay: 100,
          opacity: 1,
          translateY: 0,
          easing: 'easeOutCubic'
        }).finished
      ]);
    }
  }
  hide() {
    if (!this.state.$article || this.state.animating) return;

    this.state.animating = true;

    const $targets = this.state.$prev
      .add(this.state.$next)
      .add(this.state.$close)
      .add(this.state.$overlay);

    return Promise.all([
      anime({
        targets: $targets.get(),
        duration: 350,
        delay: 200,
        opacity: 0,
        easing: 'easeOutCubic'
      }).finished,
      anime({
        targets: this.state.$frame.get(0),
        duration: 300,
        opacity: 0,
        translateY: 20,
        easing: 'easeOutCubic'
      }).finished
    ]).then(() => {
      this.removeEvents();

      Page.bodyOverflowDisable();

      this.state.$article.remove();

      this.state.$article = null;
      this.state.$frame = null;
      this.state.$overlay = null;
      this.state.$prev = null;
      this.state.$next = null;
      this.state.$close = null;
      this.state.prevId = null;
      this.state.nextId = null;
      this.state.open = false;
      this.state.animating = false;
      this.state.location.current = null;
      this.state.title.current = null;

      this.setUrl(this.state.location.default);
      this.setTitle(this.state.title.default);
    });
  }
  prev() {
    this.open(this.state.prevId);
  }
  next() {
    this.open(this.state.nextId);
  }
  request(id) {
    const timeout = setTimeout(() => {
      this.setLoading();
    }, 600);

    return $.ajax('/news/' + id, {'type': 'post'})
      .then(data => data)
      .fail(() => {
        throw new Error('Ошибка при загрузке данных');
      })
      .always(() => {
        clearTimeout(timeout);
        this.unsetLoading();
      });
  }
  setUrl(url) {
    history.replaceState(null, null, url);
  }
  setTitle(title) {
    document.title = title;
  }
  setElements($content) {
    this.state.$article = $content;
    this.state.$overlay = $content.find(classList.overlay);
    this.state.$prev = $content.find(classList.prev);
    this.state.$next = $content.find(classList.next);
    this.state.$close = $content.find(classList.close);
    this.state.$frame = $content.find(classList.frame);
  }
  setControl() {
    this.state.$prev.css('display', this.state.prevId ? 'block' : 'none');
    this.state.$next.css('display', this.state.nextId ? 'block' : 'none');
  }
  setLoading() {
    if (this.state.$article) {
      this.state.$article.addClass('is-loading');
    }
  }
  unsetLoading() {
    if (this.state.$article) {
      this.state.$article.removeClass('is-loading');
    }
  }
}

export default new Article().init();
